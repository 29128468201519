.App {
  background-color: #eeeeee;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-pre-header {
  display: flex;
  width: 100%;
  height: 28px;
  position: absolute;
  top: 0;
  align-items: center;
  justify-content: center;
}

.App-container {
  padding-top: 28px;
  min-height: 80vh;
  display: flex;
}

.App-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-footer {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 92px;
  background: #0f1a33;
  bottom: 0;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.App-card {
  margin-bottom: 20px;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@media (max-width: 768px) {
  .App-container {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .App-title {
    padding-right: var(--bs-gutter-x, .75rem);
    padding-left: var(--bs-gutter-x, .75rem);
  }

  .App-card {
    background: linear-gradient(to bottom,
        #eeeeee,
        #eeeeee 70%,
        #0f1a33 30%,
        #0f1a33);
    margin-bottom: 0;
  }


}